export const toolbar_menu = [{
  title: 'About FISSD',
  link: '/FISSD'
},{
  title: 'Partners',
  link: '/partners'
},
{
  title: 'Products',
  link: '/products'
},
{
  title: 'Dealers Listing',
  link: '/dealersListing'
},
{
  title: 'Import/Export Hub',
  link: '//import-export.domain.nfmp.gov.ng'
},
{
  title: 'Farmers Platform',
  link: '/farmers',
  link2: '//farmers-platform.domain.nfmp.gov.ng'
},
{
  title: 'Guidelines',
  link: '/guidelines'
},
{
  title: 'Create Account',
  link: '/signup'
},
{
  title: 'Account Login',
  link: '//members-portal.domain.nfmp.gov.ng'
}
]
export default {
  toolbar_menu
}
